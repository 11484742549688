<template>
  <div class="content">
    <div class="wrapper-h1">
      <BaseH1 :text="'Hurra!'" class="h1 wrapper-h1__h1" />
      <TextDescription
        :text="'Jesteś gwiazdą! Twój profil jest opublikowany!'"
      />
    </div>

    <ButtonPrimary :to="ROUTE_LIST_NANNY" label="Ok" />
  </div>
</template>

<script>
import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import ButtonPrimary from "@/components/UI/Base/ButtonPrimary.vue";
import { ROUTE_LIST_NANNY_NAME } from "@/router/constants";

export default {
  components: { BaseH1, TextDescription, ButtonPrimary },
  setup() {
    const ROUTE_LIST_NANNY = {
      name: ROUTE_LIST_NANNY_NAME,
    };

    return { ROUTE_LIST_NANNY };
  },
};
</script>

<style scoped>
.content {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 100px;
}
.wrapper-h1 {
  width: 360px;

  margin-top: -20px;
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 20px;
}
h1.wrapper-h1__h1:deep() {
  font-size: 72px;
}

@media (max-width: 1200px) {
  .content {
    width: 100%;

    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 100px;
  }
  .wrapper-h1 {
    width: 100%;

    margin-top: -20px;
    display: flex;
    flex-flow: column;
    align-items: center;
    row-gap: 20px;
  }
  h1.wrapper-h1__h1:deep() {
    font-size: var(--h1-short--font-size);
  }
}
</style>
